<template>
	<ModalTemplate v-model="isShow" 
		name="slot-template-picker"
		queryName="m_stp"
		:closer="() => isShow = false">
		<div class="main__cont">
			<div class="header__cont">
				<div class="close__button" @click="closeModal">
					<span class="iconify"  data-width="24" data-height="24" data-icon="uil:arrow-left"></span>
				</div>
				<div class="header__title">Choose template</div>
			</div>

			<b-input placeholder="Search"
				v-model="searchString"
				icon="magnify"
				:icon-right="searchString.length > 0 ? 'close-circle' : ''"
				icon-right-clickable
				@icon-right-click="searchString = ''">
			</b-input>

			<div class="template__list">
				<div v-if="filterSearch(searchString).length > 0">
					<b-button type="is-danger is-light" 
					@click="selectTemplate(null)" expanded outlined>
						Without template
					</b-button>
					<b-collapse
					class="card"
					animation="slide"
					v-for="(template, index) of filterSearch(searchString)"
					:key="index"
					:open="openedItem == index"
					@open="openedItem = index"
					:aria-id="'contentIdForA11y5-' + index">
					<template #trigger="props">
						<div class="card-header"
							role="button"
							:aria-controls="'contentIdForA11y5-' + index"
							:aria-expanded="props.open"
						>
							<div class="card-header-title">
								<b-tag v-bind:style="{
									background: template.cellBackgroundColor,
									height: '18px',
								}"></b-tag>
								{{ template.title || "Template" }}
							</div>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-down' : 'menu-up'">
								</b-icon>
							</a>
						</div>
					</template>
					<div class="card-content" style="padding: 0">
						<div class="content">
							<TemplateDetail :template="template"/>
						</div>
					</div>
					<footer class="card-footer" >
						<a class="card-footer-item" @click="selectTemplate(template)" v-bind:style="{
							color: template.cellBackgroundColor
						}">Choose</a>
						<a class="card-footer-item" v-bind:style="{
							color: template.cellBackgroundColor
						}">Edit</a>
					</footer>
				</b-collapse>
				</div>
				
				<div class="not_found_message" v-else >
					Templates not found
				</div> 
			</div>
		</div>
	</ModalTemplate>
</template>

<script>
import ModalTemplate from '../ModalTemplate.vue';
import TemplateDetail from '../../inputs/slot-template-picker/TemplateDetail.vue';
import ModalProvider from '../../../scripts/modalProvider';


export default {
	name: 'SelectSlotTemplateModal',
	components: {
		ModalTemplate,
		TemplateDetail,
	},
	model:{
		prop:'show',
		event: 'update:show'
	},
	props: {
		show: {
			type: Boolean,
			required: true,
		},
        currentTemplate: {
            type: Object
        },
        templates: {
            type: Array,
        },
		onSelectTemplate: {
			type: Function,
			required: true,
		}
	},

	computed: {
		isShow: {
			get(){
				return this.show
			},
			set(value){
				this.$emit('update:show', value)
			}
		},
	
	},

	data() {
		return {
            openedItem: null,
            searchString: "",
		};
	},

	methods: {
		closeModal(){
			ModalProvider.back()
		},
		selectTemplate(template){
			this.closeModal()
			this.onSelectTemplate(template)
		},
		filterSearch(searchString){
            if (searchString == "")
                return this.templates
            let lowerSearchString = searchString.toLowerCase();
            let allTemplates = this.templates
            if (!allTemplates){
                return []
            }
            let templateTitle
            return allTemplates.slice(1).filter((template) => {
                templateTitle = template.title
                if (!templateTitle) {
                    templateTitle = ""
                }
                return templateTitle.toLowerCase().includes(lowerSearchString)
            })
        },
	},
};
</script>

<style scoped>
.card-header-title{
    display: flex;
    flex-direction: row;
    gap: 12px;
    font-size: 14px;
}

.header__title{
    height: 32px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}
.main__cont{
	position: absolute;
	top: 40px;
	bottom: 70px;
	left: 20px;
	right: 20px;
    background: white;
    border-radius: calc(0.25em + 8px);
    padding: 16px 8px;

    display: flex;
	flex-direction: column;
	gap: 16px;
}

.main__cont > * {
	padding: 0 0.5em;
}

.template__list{
	flex: 1;
	overflow-y: auto;
	padding-bottom: 1em;
}

input {
    width: inherit;
    padding: 6px 6px 7px 6px;
    height: 32px;
    font-size: 16px;
    border-radius: 2px;
    border: none;
}

.items__cont  {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    padding: 10px;
    padding-top: 10px;
   
}

.search__field {
    height: 34px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
    margin: 0 10px;
    display: grid;
    grid-template-columns: calc(100% - 16px - 37px) 37px;
    grid-column-gap: 16px;
}

.card__content{
    padding: 0;
}

.close__button{
    position: absolute;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear__button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.not_found_message{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}

.search__field > input {
    border: none;
}

input:focus{
    outline: none;
    box-shadow: none;
}

.without__template{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 11px;
    border-radius: 8px;
    border: 1px solid #ff7676;
    color: red;
    margin-bottom: 16px;
}
</style>