var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template__detail"},[(_vm.template.description)?_c('div',{staticClass:"template__field description",style:({
        'border-color': _vm.template.cellBackgroundColor
    })},[_vm._v(" "+_vm._s(_vm.template.description)+" ")]):_vm._e(),_c('div',{staticClass:"tag__list"},[(_vm.template.hostId && _vm.getHostName(_vm.template.hostId))?_c('b-taglist',{staticClass:"tag__item",attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":_vm.isDarkColor ? 'is-dark' : 'is-light'}},[_vm._v("Host")]),_c('b-tag',{style:({
                color: _vm.template.cellTextColor,
                background: _vm.template.cellBackgroundColor
            })},[_vm._v(_vm._s(_vm.getHostName(_vm.template.hostId)))])],1):_vm._e(),(_vm.template.levelId && _vm.getPriceLevelName(_vm.template.levelId))?_c('b-taglist',{staticClass:"tag__item",attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":_vm.isDarkColor ? 'is-dark' : 'is-light'}},[_vm._v("Price")]),_c('b-tag',{style:({
                color: _vm.template.cellTextColor,
                background: _vm.template.cellBackgroundColor
            })},[_vm._v(_vm._s(_vm.getPriceLevelName(_vm.template.levelId)))])],1):_vm._e(),(_vm.template.ratingId && _vm.getRatingName(_vm.template.ratingId))?_c('b-taglist',{staticClass:"tag__item",attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":_vm.isDarkColor ? 'is-dark' : 'is-light'}},[_vm._v("Rating")]),_c('b-tag',{style:({
                color: _vm.template.cellTextColor,
                background: _vm.template.cellBackgroundColor
            })},[_vm._v(_vm._s(_vm.getRatingName(_vm.template.ratingId)))])],1):_vm._e(),(_vm.template.maxAttenders)?_c('b-taglist',{staticClass:"tag__item",attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":_vm.isDarkColor ? 'is-dark' : 'is-light'}},[_vm._v("Max attenders")]),_c('b-tag',{style:({
                color: _vm.template.cellTextColor,
                background: _vm.template.cellBackgroundColor
            })},[_vm._v(_vm._s(_vm.template.maxAttenders))])],1):_vm._e(),(_vm.template.maxAttenders)?_c('b-taglist',{staticClass:"tag__item",attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":_vm.isDarkColor ? 'is-dark' : 'is-light'}},[_vm._v("Wait list")]),_c('b-tag',{style:({
                color: _vm.template.cellTextColor,
                background: _vm.template.cellBackgroundColor
            })},[_vm._v("enabled")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }