<template>
    <div class="card_short_settings" v-bind:class="{
		'is-modal' : isModal,
	}">
        <div class="calendar__name">
            {{calendarName}}
        </div>
        
        <div class="settings__fields" ref="settings">
            <!-- Template -->
            <div class="form__template" v-if="templates">
                <SlotTemplateSelector
                    v-model="form.template"
                    :templates="templates"
                />
            </div>
            <!-- Title -->

			<div class="title-container">
                <b-input ref="title" placeholder="Title" v-model="form.title" />
            </div>


            <!-- Date, time, duration -->

            <div class="slot__property date_duration_field">
                <div class="multi__field">
                    <DateInput v-model="form.dateString" 
						:dateFormat="dateFormat" 
						:date="form.date" 
						:form="form"
						:isDisablePastDates="!form.isShort" 
						name="date"/>
                    <TimeInput v-model="form.timeString" 
						:form="form"
						:enableCheckPastTime="!form.isShort" 
						name="time"/>
                </div>
                <div class="duration">
                    <div class="duration-label">Duration</div>
                    <BaseInput
                    	inputType="number"
                    	v-model="form.duration" placeholder="60"
                    	:form="form" name="duration"
						width="3ch"
                    	:validator="digitsOnly"
                    	:showErrorMessage="false"
					/>
                </div>
                <div class="timezone" v-if="form.timezone">
                    <span @click="() => {showTimezoneSelect = true}">
                        {{form.timezone.name}}
                    </span>
                </div>
                <div class="timezone-difference">
                    <div class="current__datetime" v-if="calendarTimezone && form.timeString.length == 5 && calendarTimezone.id != form.timezone.id">
                        {{calendarTZdateTime }}
                    </div>
                    <div class="current_week_day" v-else>
                        {{ dateOfWeek }}
                    </div>
                </div>
            </div>

            <!-- Description -->

            <textarea class="textarea" placeholder="Description"
                v-model="form.description">
            </textarea>

			<!-- <div class="slot__property">
                <b-checkbox v-model="isChargeable" type="is-info">Chargeable slot</b-checkbox>
			</div> -->


            <div class="slot__property busy_and_color">

				<!-- Is busy -->
				<b-checkbox v-model="isBusy" type="is-info">Mark as busy</b-checkbox>

				<!-- Slot color -->
				<SlotColorPickerV2 v-model="cell"/>
			</div>

			<!-- Payment types -->

			<b-tabs v-model="form.chargingRule" 
			type="is-toggle"
			:animated="false"
			>

				<!-- None type -->

				<b-tab-item label="None">
					
				</b-tab-item>

				<!-- I'm host -->

				<b-tab-item label="They pay">
					<div class="added-attenders" v-if="attenders && form.attenders.length > 0">
						<div class="added-attenders__item" 
						v-for="user in form.attenders"
						:key="`added-attender-${user.userId}`">
							<div class="added-attenders__item-title">
								{{ getAttenderNameById(user.userId, user.dependedUserId) }}
								<a v-if="user.dependedUserId">{{ `(${getAttenderNameById(user.dependedUserId)})` }}</a>
							</div>
							<div class="added-attenders__item-button">
								<b-button icon-right="close" 
									type="is-ghost is-danger" 
									@click="() => removeAttender(user.userId)"
									inverted/>
							</div>
						</div>
					</div>

					<div class="add__host">
						<b-autocomplete
							v-model="searchAttender"
							dropdown-position="top"
							field="fullName"
							ref="attenders"
							open-on-focus
							clear-on-select
							:data="search(attenders, searchAttender)"
							placeholder="Select Attendees"
							clearable
							@select="selectAttender">
							<template #empty>No results found</template>
						</b-autocomplete>
						<b-button icon-right="account-plus" 
							type="is-ghost is-info" 
							@click="redirectTo('attender-settings')"
							inverted/>
					</div>
				</b-tab-item>

				<!-- I'm attender -->

				<b-tab-item label="I pay">
					<div class="add__host">
						<b-select v-model="form.hostId" @input="selectHostHandler" 
						placeholder="Select Host" expanded>
							<option
								v-for="host in hosts"
								:value="host.id"
								:key="`host_${host.id}`">
								{{ host.name }}
							</option>
						</b-select>
						<b-button icon-right="account-plus" 
							type="is-ghost is-info" 
							@click="redirectTo('host-settings')"
							inverted/>
					</div>
				</b-tab-item>
			</b-tabs>

			<!-- Slot Host -->



            <!-- Slot guests -->
            <!-- <div class="slot__property add_guest">
                <div class="search__cont">
                    <div class="icon">
                        <span class="iconify" data-icon="uil:users-alt" data-width="24" data-height="24"></span>
                    </div>
                    <div class="search__field" @click="showSearchGuestList">
                        <b-input v-model="searchGuest"  placeholder="Add guest"></b-input>
                    </div>
                </div>
                <div class="guests__cont">
                    <div class="guest" v-for="guest, index in searchGuestList" :key="`guest__${index}`">
                        <div class="guest_avatar_cont">
                            <div class="guest__avatar"></div>
                        </div>
                        <div class="guest__name">
                            {{guest.name}}
                        </div>
                        <div class="guest_del_button" @click="() => deleteGuest(guest.id)">
                            <span class="iconify" data-icon="uil:multiply" data-width="20" data-height="20"></span>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="form__footer">
            <div class="template__tools">
                <b-checkbox v-model="form.isCreateTemplate">Create template</b-checkbox>
            </div>
            <div class="submit_buttons_cont">
                <b-button type="is-info" @click="submitHandler(form)">
                    Save
                </b-button>
                <b-button type="is-info is-light" @click="closeHandler(form.date)">
                    Cancel
                </b-button>
            </div>
        </div>
        <TimezoneSelector
            v-model="form.timezone"
            v-show="showTimezoneSelect"
            :show="showTimezoneSelect"
            :closer="() => {showTimezoneSelect = false}"
        />
    </div>
</template>

<script>
import Slot from '../../scripts/slot';
import TimeInput from '../inputs/TimeInput.vue';
import DateInput from '../inputs/DateInput.vue';
import TimezoneSelector from '../inputs/timezone-selector/TimezoneSelector.vue';
import { convertTZ, getStringByFormat, isEqualDate, UTCString } from '../../scripts/date';
import CMS from '../../service/cms/service';
import SlotSettingsFormInstance from '../forms/slot-settings/formInstance';
import BaseInput from '../inputs/BaseInput.vue';
import TextareaInput from '../inputs/TextareaInput.vue';
import SlotTemplateSelector from '../inputs/slot-template-picker/SlotTemplateSelector.vue';
import Host from '../../scripts/host';
import Attender from '../../scripts/attender';
import SlotColorPickerV2 from '../inputs/SlotColorPickerV2.vue';

const WEEK_DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export default {
    name: 'SlotShortSettings',
    components: {
        TimeInput,
        BaseInput,
        DateInput,
        TextareaInput,
        SlotColorPickerV2,
        TimezoneSelector,
        SlotTemplateSelector,
    },
    props: {
        eventSlot: Slot || Object,
		isModal: Boolean,
        submitHandler: {
            type: Function,
            required: true,
        },
        closeHandler: {
            type: Function,
            required: true,
        },
    },
    watch: {
        'form.date' : {
            handler(newValue){
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
            }, 
            deep: true,
        },
        'form.timezone' : {
            handler(newValue){
                if (!newValue) return
                this.form.timezone = newValue
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)   
            },
            deep: true,
        },
        'form.template' : {
            handler(newTemplate, oldTemplate){
                console.log('newTemplate :>> ', newTemplate);
                console.log('oldTemplate :>> ', oldTemplate);
                this.form.updateByTemplate(newTemplate, oldTemplate)
            }, 
            deep: true,
        },
        'form.duration' : {
            handler(duration){
                if (typeof duration == 'string'){
                    this.form.fields['duration'].setError("duration is invalid")
                }

            },  
            deep: true,
        },
        'form.timeString' : {
            handler(newValue) {
                let timeField = this.form.fields["time"]
                if (newValue.length != 5 || timeField.isError) 
                    return
                
                let browserTimezone = this.$store.getters.browserTimezone
                this.form.updateTime(newValue, browserTimezone)
            },
            deep: true,
        },
        'form.dateString' : {
            handler(newValue, oldValue) {

                if (newValue.length != 10) return

                let browserTimezone = this.$store.getters.browserTimezone
                if (oldValue) {
                    let workStart = this.$store.getters.calendarWorkStart
                    let workEnd = this.$store.getters.calendarWorkEnd 
                    this.form.updateDate(newValue, browserTimezone, {
                        start: workStart,
                        end: workEnd,
                    })
                }
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
            },
            deep: true,
        },
		'form.chargingRule': {
			handler(newValue) {
				if (newValue == 2 && this.hosts.length == 0 && !this.isHostsLoaded) {
					CMS.settings.getHosts(this.$route.params.calendarUid)
						.then(data => {
							if (data && data.err)
								throw data.err
							if (!data.hosts)
								return
							this.hosts = data.hosts.map(hostData => new Host(hostData))
						})
						.catch((err) => {console.error(err)})
						.finally(() => this.isHostsLoaded = true)
				} else if (newValue == 1 && this.attenders.length == 0 && !this.isAttenderLoaded) {
					CMS.attenders.list(this.$route.params.calendarUid)
						.then(data => {
							if (data && data.err)
								throw data.err
							let attenders = []
							if (data.active) {
								attenders = data.active.map(attenderData => {
									let attender = new Attender(attenderData)
									if (attender.householdId){
										attender.fullName += ` (${data.household[attender.householdId]})`
									}
									return attender
								})
							}
							this.attenders = attenders
						})
						.catch((err) => {console.error(err)})
						.finally(() => this.isAttenderLoaded = true)
				}
			},
			deep: true,
		},

    },
    computed: {
        calendarUid(){
			return this.$route.params.calendarUid
		},
		calendar(){
			return this.$store.getters.currentCalendar()
		},
        calendarName(){
			if (!this.calendar)
				return ""
            return this.calendar.title
        },
		userHaveAccess(){
			if (!this.calendar)
				return false
			return this.calendar.isEditable
		},
        templates(){
			return this.$store.getters.calendarSlotTemplates
		},

        dateFormat(){
            return this.$store.getters.dateFormat
        },
        dateOfWeek(){
            return WEEK_DAYS[this.form.date.getDay()]
        },
        calendarTimezone(){
            const calendarTimezone = this.$store.getters.calendarTimezone
            if (!this.form)
                return calendarTimezone
            this.form.calendarTimezone = calendarTimezone
            if (calendarTimezone.id != -1 && !this.form.timezone){

                let slotTZid = this.form.timezoneId ?? calendarTimezone.id
                this.form.timezone = this.$store.getters.getTimezoneByID(slotTZid)

                if (calendarTimezone.id != this.form.timezoneId){
                    this.form.date = convertTZ(this.form.date, calendarTimezone, this.form.timezone)
                    this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
                } 
				
                this.form.dateFormat = this.dateFormat
                this.form.dateString = getStringByFormat(this.dateFormat.format, this.form.date)
            }
            return calendarTimezone
        },

        cell:{
			get(){
				return {
					mainColor: this.form.cellBackgroundColor,
					textColor: this.form.cellTextColor,
				}
			},
			set(newValue){
				this.form.cellBackgroundColor = newValue.mainColor
				this.form.cellTextColor = newValue.textColor
			}
		},
    },
    data() {
        return {
            
            form: null,
			hosts: [],
			attenders: [],
			isHostsLoaded: false,
			isAttenderLoaded: false,

            showTimezoneSelect: false,
            calendarTZdateTime: null,
            isBusy: false,
			isChargeable: false,

            searchGuest: "",
            searchGuestList: [],
			searchAttender: "",
			templateIsLoaded: false,
        };
    },

    created() {
		if (this.eventSlot){
			let userId = this.$store.getters.userId
			if (this.eventSlot.settings.hostId && this.eventSlot.settings.hostId != userId)
				this.isChargeable = true
            this.form = new SlotSettingsFormInstance({
                ...this.eventSlot.settings,
                startAt: this.eventSlot.period.start.date,
                duration: this.eventSlot.period.duration,
                attenders: this.eventSlot.attenders,
            }, true)
        } else {
            let date = null
            let tableHeader = this.$store.getters.tableHeader
			if (this.$route.query["dt"])
				date = new Date(this.$route.query["dt"])
            else if (tableHeader)
                date = tableHeader.selectedDate
            this.form = new SlotSettingsFormInstance({
                startAt: date,
                isCreate: true,
                timezoneId : this.calendarTimezone.id
            }, true)
            this.form.timeString = ""   
        }

		const slotIndex = this.eventSlot && this.eventSlot.index ?
			this.eventSlot.index : 0
		this.form.loadCache(slotIndex)

		const updater = () => {
			if (this.calendar)
				this.$store.dispatch('setup-slot-templates')
			else
				setTimeout(updater, 100)
		}

        if (this.userHaveAccess) {
			updater()
		}
    },

    methods: {
        calendarDateTimeInTZ(initDate){
            if (!this.form || !this.form.timezone)
                return
            let date = convertTZ(initDate, this.form.timezone, this.calendarTimezone)
            let calendarTZName = this.calendarTimezone.name.split(' ')[0]
            let result = date.toTimeString().slice(0,5) + ` ${calendarTZName}`

            if (date.getDate() != initDate.getDate()){
                result = date.toLocaleDateString() + " " + result
            }

            this.form.calendarDate = date

            return result
        },

        // deleteGuest(guestId){
        //     let guestIndex = this.searchGuestList.findIndex(guest => guest.id == guestId)
        //     console.log('guestIndex', guestIndex)
        //     if (guestIndex == -1) return

        //     this.$delete(this.searchGuestList, guestIndex);
        // },
        // showSearchGuestList(){
        //     this.searchGuestList = [
        //         {name: "Timur", id:2},
        //         {name: "Timur", id:3},
        //     ]
        // },
        digitsOnly(value){
            let fixed = value.split('').filter(char => {
                return char != '.' && char != ',' && char != '-'
            }).join('')

            return fixed
        },
		redirectTo(name){
			if (name != 'attender-settings' && name != 'host-settings')
				return

			this.form.cache()
			
			if (name == "attender-settings") {
				this.$router.push({
					name: 'calendar-settings-attenders-menu'
				})
			} else if (name == "host-settings") {
				this.$router.push({
					name: 'calendar-settings-host-menu'
				})
			}
		},
		selectHostHandler(){
			const host = this.hosts.find(host => host.id == this.form.hostId)
			if (!host )
				return ;
			this.form.cellBackgroundColor = host.defaultBackgroundColor == "" ? 
				"#e6e6fa" : host.defaultBackgroundColor 
		},
        search(items, searchString){

			items = items
			.filter(item => {
				return !this.form.attenders.find(attender => {
					return attender.userId == item.userId
				})
			})

            if (!searchString || searchString.length == 0)
                return items
            
            let search = searchString.toLowerCase()

            return items.filter(item => {
                return item.fullName.toLowerCase().includes(search)
            })
        },
		checkAttender(userId){
			return !!this.form.attenders.find(attender => {
				return attender.userId == userId
			})
		},
		selectAttender(attender){
			if (!attender)
				return
			const isContainAttender = this.checkAttender(attender.userId)
			if (!isContainAttender){
				this.form.attenders.push({
					userId: attender.userId,
				})

			}
			const settings = this.$refs['settings']
			if (!settings)
				return
			setTimeout(() => settings.scrollTop = settings.scrollHeight, 0)
			
		},
		removeAttender(userId){
			this.form.attenders = this.form.attenders.filter(user => {
				return user.userId != userId
			})
		},
		getAttenderNameById(userId, isDependent = false){
			if (isDependent) {
				let attender = this.attenders
					.find(attender => attender.dependents.find(dependent => dependent.userId == userId))
				if (!attender)
					return ""
				let dependent = attender.dependents.find(dependent => dependent.userId == userId)
				return dependent.fullName
			}
			let attender = this.attenders.find(attender => attender.userId == userId)
			if (!attender)
				return ""
			return attender.fullName
		},
    },
};
</script>

<style>
.tabs a {
    display: flex;
    justify-content: center;
    align-items: center;
	padding: 0.5em 1em;
}

.b-tabs .tab-content {
	height: auto;
	padding: 0;
	padding-top: 16px;
}

.content ul{
	margin: 0;
}

/* .tabs li {
	flex: 1;
} */

</style>

<style scoped>
.textarea:not([rows]){
    min-height: 5em;
    max-height: 9em;
}

.form__template{
    display: flex;
    justify-content: flex-end;
} 

.card_short_settings{
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
}

.card_short_settings.is-modal{
	height: calc(100dvh - 32px);
}


input[type='checkbox']{
    height: 18px;
    width: 18px;
}

.slot__property{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.title-container{
	width: 100%;
}

.busy_and_color{
    justify-content: space-between;
}
.add_guest{
    flex-direction: column;
}

.settings__fields{
    min-width: 260px;
    height: 100%;
	flex: 1;
	overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
	padding-bottom: 26px
}

.is__busy{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.multi__field{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.timezone{
    color: rgb(0, 117, 255);
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
}

.current__datetime{
    text-align: right;
    font-size: 11px;
}


.duration-label{
	font-size: 0.85em;
}

.current_week_day{
    text-align: right;
    font-size: 11px;
}

.date_duration_field{
    display: grid;
    grid-template-columns: calc(100% - 16px - 120px) 120px;
    grid-column-gap: 16px;
    grid-row-gap: 7px;
}

.search__cont{
    display: flex;
    align-items: center;
    gap: 16px;
}

.search__cont > .search__field {
    width: 100%;
}

.calendar__name{
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    font-weight: 600;
    border-bottom: 2px solid rgb(240, 240, 240);
}
.submit_buttons_cont{
    padding: 20px;
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    background: white;
    height: 40px;
}

.guests__cont{
    margin-left: 40px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.guest{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
}

.guest > *{
    display: flex;
    align-items: center;
}

.guest__avatar{
    border:1px solid black;
    border-radius: 20px;
    width: 32px;
    height: 32px;
}
.guest__name{
    width: calc(100% - 32px * 2);
}

/* .guest_del_button{

} */

.duration{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
}

.form__footer{
    /* position: fixed;
    bottom: 0;
    right: 0; */

    display: flex;
    gap: 16px;
    flex-direction: column;

    width: 100%;
    background: white;
    border-top: 1px solid lightgrey;
    padding-bottom: 16px;
    z-index: 5;

}

.template__tools{
    padding: 10px 24px;
    height: 40px;
}

.choose_template_cont{
    display: flex;
    justify-content: flex-end;
}

.checkbox__cont{
    display: grid;
    grid-template-columns: 18px calc(100% - 10px - 18px) ;
    grid-column-gap: 10px;
}

.checkbox__cont > *{
    display: flex;
    align-items: center;
}

.add__host {
	display: grid;
	column-gap: 16px;
	grid-template-columns: auto 40px;
}

.added-attenders{
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	border: 1px solid lightgrey;
	border-radius: 5px;
	max-height: 163px;
	overflow-y: auto;
}

.added-attenders__item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.added-attenders__item + .added-attenders__item {
	border-top: 1px solid lightgrey;
}

.added-attenders__item-title{
	padding: 7px 11px;
}

</style>