<template>
    <div class="slot-color-picker" v-click-outside="closeHandler">
        <div class="slot" @click="show = !show" v-bind:style="styles">
            Text
        </div>
		<transition name="open">
			<div class="colors-popup" v-if="show">
				<div class="colors-popup__item" 
				v-for="color, index in colors"
				:key="`color-${index}`" v-bind:style="{
					'background': color,
				}" @click="changeColor(color)">
				</div>
			</div>
		</transition>
    </div>
</template>

<script>
export default {
    name: 'SlotColorPickerV2',
    model:{
        prop:'color',
        event: 'update:color'
    },
    props: {
        color: {
            type: Object,
            required: true,
        },
    },
	directives: {
		'click-outside': {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					if (!(el == event.target || el.contains(event.target))) {
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent)
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent)
			},
        }
    },
	computed: {
		changedColor: {
			get(){
				return this.color
			},
			set(value){
				this.$emit('update:color', value)
			}
		},
		styles(){
			return {
				'background': this.color.mainColor,
				'color': this.color.textColor
			}
		}
	},
    data() {
        return {
            show: false,
			colors: [
				"#a9bcff",
				"#9adcff",
				"#b1f994",
				"#ffff89",
				"#ffd493",
				"#ff9f8c",
				"#ffbdda",
			],
        };
    },
	methods: {
		closeHandler(){
			this.show = false
		},
		changeColor(color){
			this.changedColor = {
				mainColor: color,
				textColor: this.color.textColor
			}
		}
	}
};
</script>

<style scoped>
.slot-color-picker{
    width: 106px;
	position: relative;
}
.slot{
    height: 36px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
	border: 1px solid #dbdbdb;
}

.colors-popup{
	border: 1px solid #dbdbdb;
	border-radius: 4px;
	position: absolute;
	right: 0;
	bottom: 46px;
	
	width: fit-content;
	height: fit-content;
	background: white;

	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 1em;
	padding: 1em;
}

.colors-popup__item{
	width: 1.4em;
	height: 1.4em;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
}

.open-enter-active {
	animation: open-anime .25s;
}	
.open-leave-active {
	animation: open-anime .25s reverse;
}

@keyframes open-anime {
	from {
		opacity: 0;
		bottom: 36px;
	}
	to {
		opacity: 1;
		bottom: 46px;
	}
}

</style>