var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalTemplate',{attrs:{"name":"slot-template-picker","queryName":"m_stp","closer":function () { return _vm.isShow = false; }},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"main__cont"},[_c('div',{staticClass:"header__cont"},[_c('div',{staticClass:"close__button",on:{"click":_vm.closeModal}},[_c('span',{staticClass:"iconify",attrs:{"data-width":"24","data-height":"24","data-icon":"uil:arrow-left"}})]),_c('div',{staticClass:"header__title"},[_vm._v("Choose template")])]),_c('b-input',{attrs:{"placeholder":"Search","icon":"magnify","icon-right":_vm.searchString.length > 0 ? 'close-circle' : '',"icon-right-clickable":""},on:{"icon-right-click":function($event){_vm.searchString = ''}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('div',{staticClass:"template__list"},[(_vm.filterSearch(_vm.searchString).length > 0)?_c('div',[_c('b-button',{attrs:{"type":"is-danger is-light","expanded":"","outlined":""},on:{"click":function($event){return _vm.selectTemplate(null)}}},[_vm._v(" Without template ")]),_vm._l((_vm.filterSearch(_vm.searchString)),function(template,index){return _c('b-collapse',{key:index,staticClass:"card",attrs:{"animation":"slide","open":_vm.openedItem == index,"aria-id":'contentIdForA11y5-' + index},on:{"open":function($event){_vm.openedItem = index}},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"card-header",attrs:{"role":"button","aria-controls":'contentIdForA11y5-' + index,"aria-expanded":props.open}},[_c('div',{staticClass:"card-header-title"},[_c('b-tag',{style:({
								background: template.cellBackgroundColor,
								height: '18px',
							})}),_vm._v(" "+_vm._s(template.title || "Template")+" ")],1),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up'}})],1)])]}}],null,true)},[_c('div',{staticClass:"card-content",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"content"},[_c('TemplateDetail',{attrs:{"template":template}})],1)]),_c('footer',{staticClass:"card-footer"},[_c('a',{staticClass:"card-footer-item",style:({
						color: template.cellBackgroundColor
					}),on:{"click":function($event){return _vm.selectTemplate(template)}}},[_vm._v("Choose")]),_c('a',{staticClass:"card-footer-item",style:({
						color: template.cellBackgroundColor
					})},[_vm._v("Edit")])])])})],2):_c('div',{staticClass:"not_found_message"},[_vm._v(" Templates not found ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }