<template>
    <div class="template__detail">
        <div class="template__field description" v-bind:style="{
            'border-color': template.cellBackgroundColor
        }" v-if="template.description">
            {{template.description}}
        </div>

        <div class="tag__list">
            <b-taglist class="tag__item" v-if="template.hostId && getHostName(template.hostId)" attached>
                <b-tag :type="isDarkColor ? 'is-dark' : 'is-light'">Host</b-tag>
                <b-tag v-bind:style="{
                    color: template.cellTextColor,
                    background: template.cellBackgroundColor
                }">{{getHostName(template.hostId)}}</b-tag>
            </b-taglist>

            <b-taglist class="tag__item" v-if="template.levelId && getPriceLevelName(template.levelId)" attached>
                <b-tag :type="isDarkColor ? 'is-dark' : 'is-light'">Price</b-tag>
                <b-tag v-bind:style="{
                    color: template.cellTextColor,
                    background: template.cellBackgroundColor
                }">{{getPriceLevelName(template.levelId)}}</b-tag>
            </b-taglist>

            <b-taglist class="tag__item" v-if="template.ratingId && getRatingName(template.ratingId)" attached>
                <b-tag :type="isDarkColor ? 'is-dark' : 'is-light'">Rating</b-tag>
                <b-tag v-bind:style="{
                    color: template.cellTextColor,
                    background: template.cellBackgroundColor
                }">{{getRatingName(template.ratingId)}}</b-tag>
            </b-taglist>

            <b-taglist class="tag__item" v-if="template.maxAttenders" attached>
                <b-tag :type="isDarkColor ? 'is-dark' : 'is-light'">Max attenders</b-tag>
                <b-tag v-bind:style="{
                    color: template.cellTextColor,
                    background: template.cellBackgroundColor
                }">{{template.maxAttenders}}</b-tag>
            </b-taglist>

            <b-taglist class="tag__item" v-if="template.maxAttenders" attached>
                <b-tag :type="isDarkColor ? 'is-dark' : 'is-light'">Wait list</b-tag>
                <b-tag v-bind:style="{
                    color: template.cellTextColor,
                    background: template.cellBackgroundColor
                }">enabled</b-tag>
            </b-taglist>
        </div>

    </div>
</template>

<script>
import { hexToRgb } from '../../../scripts/color';
export default {
    name: 'TemplateDetail',
    props: {
        template: Object
    },
    computed: {
        isDarkColor(){
            let ceilBackgroundColor = this.template.cellBackgroundColor
            let rgbColor
            if (ceilBackgroundColor.includes("#"))
                rgbColor = hexToRgb(ceilBackgroundColor)
            else if (ceilBackgroundColor.includes("rgb"))
                rgbColor = ceilBackgroundColor
            else
                return false
            let red = rgbColor[0]
            let green = rgbColor[1]
            let blue = rgbColor[2]

            let isDarkColor = 1 - (0.299 * red + 0.587 * green + 0.114 * blue) / 255 < 0.5
            return !isDarkColor
        },
    },
    methods: {
        getHostName(hostId){
            let hosts = this.$store.getters.calendarHosts
            if (!hosts || !hosts.length)
                return ""
            let host = hosts.find((h) => h.id == hostId)
            if (!host)
                return ""
            return host.name
        },
        getPriceLevelName(priceLevelId){
            let prices = this.$store.getters.calendarPriceLevels
            if (!prices || !prices.length)
                return ""
            let price = prices.find((p) => p.id == priceLevelId)
            if (!price)
                return ""
            return price.name
        },
        getRatingName(ratingId){
            let ratings = this.$store.getters.calendarRatings
            if (!ratings || !ratings.length)
                return ""
            let rating = ratings.find((r) => r.id == ratingId)
            console.log(rating)
            if (!rating)
                return ""
            return rating.name
        },

    }
};
</script>

<style scoped>
.template__detail{
    padding: 12px;
    max-height: 300px;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tag__list{
    display: flex;
    flex-direction: column;
}

.tag__item{
    margin: 0;
}

.template__field.description{
    border: 1px solid rgb(201, 201, 201);
    border-radius: 8px;
    padding: 10px;
}

.template__field.main{
    display: grid;
    grid-template-columns: 98px auto;
    grid-column-gap: 16px;
}

.template__field.condition{
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>