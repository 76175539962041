<template>
    <div class="slot_template_selector">
        <div class="label__cont" v-if="templates.length">
            <div class="label" @click="show = true">
                <a v-if="currentTemplate && currentTemplate.title">{{currentTemplate.title}}</a>
                <a v-else>Choose template</a>
            </div>
        </div>
		<SelectSlotTemplateModal 
			v-model="show" 
			:templates="templates"
			:currentTemplate="currentTemplate"
			:onSelectTemplate="selectTemplate"
		/>
    </div>
</template>

<script>
import SelectSlotTemplateModal from '../../modals/select-slot-template/SelectSlotTemplateModal.vue';

export default {
    name: 'SlotTemplateSelector',
    model:{
        prop:'currentTemplate',
    },
    components:{
        SelectSlotTemplateModal,
    },
    props: {
        currentTemplate: {
            type: Object
        },
        templates: {
            type: Array,
        },
    },

	computed: {
		templateCalc:{
			get(){
				return this.currentTemplate
			},
			set(value){
				this.$emit('update:currentTemplate', value)
			}
		}
	},

    data() {
        return {
            show: false,
        };
    },
    methods: {
        selectTemplate(template){
            this.$emit('input', template)
        },
    },
};
</script>

<style scoped>
.label{
    color: rgb(0, 117, 255);
    text-decoration: underline;
    font-size: 12px;
}
</style>